// CAUTION: Do not edit this file. It has been generated automatically.
// NOTE: You can add more icons by editing config/fontawesome.yml and
// running `bin/fontawesome` after.
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faFacebookSquare as fabFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faGithub as fabGithub } from '@fortawesome/free-brands-svg-icons/faGithub'
import { faGithubSquare as fabGithubSquare } from '@fortawesome/free-brands-svg-icons/faGithubSquare'
import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faLinkedinIn as fabLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faSkype as fabSkype } from '@fortawesome/free-brands-svg-icons/faSkype'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faTwitterSquare as fabTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare'
import { faYoutubeSquare as fabYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare'
import { faArchive as falArchive } from '@fortawesome/pro-light-svg-icons/faArchive'
import { faArrowLeft as falArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faDigging as falDigging } from '@fortawesome/pro-light-svg-icons/faDigging'
import { faEnvelopeOpen as falEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen'
import { faFile as falFile } from '@fortawesome/pro-light-svg-icons/faFile'
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faHourglassHalf as falHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf'
import { faLockOpenAlt as falLockOpenAlt } from '@fortawesome/pro-light-svg-icons/faLockOpenAlt'
import { faMapMarkerAlt as falMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faPaperPlane as falPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faRepeat as falRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat'
import { faRocketLaunch as falRocketLaunch } from '@fortawesome/pro-light-svg-icons/faRocketLaunch'
import { faSlash as falSlash } from '@fortawesome/pro-light-svg-icons/faSlash'
import { faStar as falStar } from '@fortawesome/pro-light-svg-icons/faStar'
import { faSync as falSync } from '@fortawesome/pro-light-svg-icons/faSync'
import { faTags as falTags } from '@fortawesome/pro-light-svg-icons/faTags'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTrashAlt as falTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faTrophy as falTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy'
import { faUserCircle as falUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle'
import { faUserTimes as falUserTimes } from '@fortawesome/pro-light-svg-icons/faUserTimes'
import { faAddressCard as farAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard'
import { faArchive as farArchive } from '@fortawesome/pro-regular-svg-icons/faArchive'
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faBan as farBan } from '@fortawesome/pro-regular-svg-icons/faBan'
import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faBookmark as farBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark'
import { faBriefcase as farBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase'
import { faBrowser as farBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser'
import { faBullhorn as farBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn'
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faCalendarCheck as farCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck'
import { faChartBar as farChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faCheckDouble as farCheckDouble } from '@fortawesome/pro-regular-svg-icons/faCheckDouble'
import { faCheckSquare as farCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare'
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronSquareRight as farChevronSquareRight } from '@fortawesome/pro-regular-svg-icons/faChevronSquareRight'
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faCog as farCog } from '@fortawesome/pro-regular-svg-icons/faCog'
import { faCogs as farCogs } from '@fortawesome/pro-regular-svg-icons/faCogs'
import { faCommentCheck as farCommentCheck } from '@fortawesome/pro-regular-svg-icons/faCommentCheck'
import { faCommentDots as farCommentDots } from '@fortawesome/pro-regular-svg-icons/faCommentDots'
import { faCommentPlus as farCommentPlus } from '@fortawesome/pro-regular-svg-icons/faCommentPlus'
import { faComments as farComments } from '@fortawesome/pro-regular-svg-icons/faComments'
import { faConstruction as farConstruction } from '@fortawesome/pro-regular-svg-icons/faConstruction'
import { faCrown as farCrown } from '@fortawesome/pro-regular-svg-icons/faCrown'
import { faDesktop as farDesktop } from '@fortawesome/pro-regular-svg-icons/faDesktop'
import { faDigging as farDigging } from '@fortawesome/pro-regular-svg-icons/faDigging'
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faEllipsisH as farEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faEnvelopeOpen as farEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen'
import { faEuroSign as farEuroSign } from '@fortawesome/pro-regular-svg-icons/faEuroSign'
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle as farExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faEyeSlash as farEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash'
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faFileImage as farFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage'
import { faFilePlus as farFilePlus } from '@fortawesome/pro-regular-svg-icons/faFilePlus'
import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons/faFilter'
import { faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons/faFlag'
import { faFlagCheckered as farFlagCheckered } from '@fortawesome/pro-regular-svg-icons/faFlagCheckered'
import { faFolders as farFolders } from '@fortawesome/pro-regular-svg-icons/faFolders'
import { faGlobe as farGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe'
import { faHandPointUp as farHandPointUp } from '@fortawesome/pro-regular-svg-icons/faHandPointUp'
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart'
import { faHistory as farHistory } from '@fortawesome/pro-regular-svg-icons/faHistory'
import { faHourglass as farHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass'
import { faHourglassHalf as farHourglassHalf } from '@fortawesome/pro-regular-svg-icons/faHourglassHalf'
import { faInbox as farInbox } from '@fortawesome/pro-regular-svg-icons/faInbox'
import { faInboxIn as farInboxIn } from '@fortawesome/pro-regular-svg-icons/faInboxIn'
import { faInboxOut as farInboxOut } from '@fortawesome/pro-regular-svg-icons/faInboxOut'
import { faInfo as farInfo } from '@fortawesome/pro-regular-svg-icons/faInfo'
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faLink as farLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faListUl as farListUl } from '@fortawesome/pro-regular-svg-icons/faListUl'
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faLockOpen as farLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen'
import { faMapMarkerAlt as farMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'
import { faMinusCircle as farMinusCircle } from '@fortawesome/pro-regular-svg-icons/faMinusCircle'
import { faMobile as farMobile } from '@fortawesome/pro-regular-svg-icons/faMobile'
import { faMousePointer as farMousePointer } from '@fortawesome/pro-regular-svg-icons/faMousePointer'
import { faNewspaper as farNewspaper } from '@fortawesome/pro-regular-svg-icons/faNewspaper'
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip'
import { faPause as farPause } from '@fortawesome/pro-regular-svg-icons/faPause'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faPenSquare as farPenSquare } from '@fortawesome/pro-regular-svg-icons/faPenSquare'
import { faPhone as farPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faPhoneAlt as farPhoneAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneAlt'
import { faPlusCircle as farPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faRedoAlt as farRedoAlt } from '@fortawesome/pro-regular-svg-icons/faRedoAlt'
import { faRepeat as farRepeat } from '@fortawesome/pro-regular-svg-icons/faRepeat'
import { faRobot as farRobot } from '@fortawesome/pro-regular-svg-icons/faRobot'
import { faRocketLaunch as farRocketLaunch } from '@fortawesome/pro-regular-svg-icons/faRocketLaunch'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faSearchPlus as farSearchPlus } from '@fortawesome/pro-regular-svg-icons/faSearchPlus'
import { faShieldAlt as farShieldAlt } from '@fortawesome/pro-regular-svg-icons/faShieldAlt'
import { faShieldCheck as farShieldCheck } from '@fortawesome/pro-regular-svg-icons/faShieldCheck'
import { faShoppingCart as farShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart'
import { faSignInAlt as farSignInAlt } from '@fortawesome/pro-regular-svg-icons/faSignInAlt'
import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut'
import { faSort as farSort } from '@fortawesome/pro-regular-svg-icons/faSort'
import { faSpinnerThird as farSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird'
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faStopwatch as farStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch'
import { faSync as farSync } from '@fortawesome/pro-regular-svg-icons/faSync'
import { faTachometerAltAverage as farTachometerAltAverage } from '@fortawesome/pro-regular-svg-icons/faTachometerAltAverage'
import { faTag as farTag } from '@fortawesome/pro-regular-svg-icons/faTag'
import { faTags as farTags } from '@fortawesome/pro-regular-svg-icons/faTags'
import { faTasks as farTasks } from '@fortawesome/pro-regular-svg-icons/faTasks'
import { faThumbsUp as farThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp'
import { faTicketAlt as farTicketAlt } from '@fortawesome/pro-regular-svg-icons/faTicketAlt'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faTimesOctagon as farTimesOctagon } from '@fortawesome/pro-regular-svg-icons/faTimesOctagon'
import { faTimesSquare as farTimesSquare } from '@fortawesome/pro-regular-svg-icons/faTimesSquare'
import { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faTrophy as farTrophy } from '@fortawesome/pro-regular-svg-icons/faTrophy'
import { faUpload as farUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faUserCircle as farUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle'
import { faUserEdit as farUserEdit } from '@fortawesome/pro-regular-svg-icons/faUserEdit'
import { faUserShield as farUserShield } from '@fortawesome/pro-regular-svg-icons/faUserShield'
import { faUsers as farUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faUsersCrown as farUsersCrown } from '@fortawesome/pro-regular-svg-icons/faUsersCrown'
import { faAddressCard as fasAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faArrowSquareUp as fasArrowSquareUp } from '@fortawesome/pro-solid-svg-icons/faArrowSquareUp'
import { faArrows as fasArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import { faAward as fasAward } from '@fortawesome/pro-solid-svg-icons/faAward'
import { faBan as fasBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faBolt as fasBolt } from '@fortawesome/pro-solid-svg-icons/faBolt'
import { faBookmark as fasBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark'
import { faBriefcase as fasBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faCommentAltPlus as fasCommentAltPlus } from '@fortawesome/pro-solid-svg-icons/faCommentAltPlus'
import { faCommentCheck as fasCommentCheck } from '@fortawesome/pro-solid-svg-icons/faCommentCheck'
import { faCommentsAlt as fasCommentsAlt } from '@fortawesome/pro-solid-svg-icons/faCommentsAlt'
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faCreditCard as fasCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faExclamationSquare as fasExclamationSquare } from '@fortawesome/pro-solid-svg-icons/faExclamationSquare'
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faFileCertificate as fasFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate'
import { faFileInvoice as fasFileInvoice } from '@fortawesome/pro-solid-svg-icons/faFileInvoice'
import { faFlagCheckered as fasFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered'
import { faFolderPlus as fasFolderPlus } from '@fortawesome/pro-solid-svg-icons/faFolderPlus'
import { faFolders as fasFolders } from '@fortawesome/pro-solid-svg-icons/faFolders'
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons/faHeart'
import { faInbox as fasInbox } from '@fortawesome/pro-solid-svg-icons/faInbox'
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import { faMinusOctagon as fasMinusOctagon } from '@fortawesome/pro-solid-svg-icons/faMinusOctagon'
import { faPhoneAlt as fasPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import { faQuestionCircle as fasQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faRedo as fasRedo } from '@fortawesome/pro-solid-svg-icons/faRedo'
import { faRobot as fasRobot } from '@fortawesome/pro-solid-svg-icons/faRobot'
import { faRssSquare as fasRssSquare } from '@fortawesome/pro-solid-svg-icons/faRssSquare'
import { faShieldCheck as fasShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck'
import { faSortAlt as fasSortAlt } from '@fortawesome/pro-solid-svg-icons/faSortAlt'
import { faSquare as fasSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faStarHalf as fasStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf'
import { faStars as fasStars } from '@fortawesome/pro-solid-svg-icons/faStars'
import { faTachometerAltAverage as fasTachometerAltAverage } from '@fortawesome/pro-solid-svg-icons/faTachometerAltAverage'
import { faTags as fasTags } from '@fortawesome/pro-solid-svg-icons/faTags'
import { faThumbsDown as fasThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown'
import { faThumbsUp as fasThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { faTicketAlt as fasTicketAlt } from '@fortawesome/pro-solid-svg-icons/faTicketAlt'
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faTrashAlt as fasTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { faTrophy as fasTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy'
import { faTrophyAlt as fasTrophyAlt } from '@fortawesome/pro-solid-svg-icons/faTrophyAlt'
import { faUndoAlt as fasUndoAlt } from '@fortawesome/pro-solid-svg-icons/faUndoAlt'
import { faUnlockAlt as fasUnlockAlt } from '@fortawesome/pro-solid-svg-icons/faUnlockAlt'
import { faUserCircle as fasUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faUserEdit as fasUserEdit } from '@fortawesome/pro-solid-svg-icons/faUserEdit'
import { faUserShield as fasUserShield } from '@fortawesome/pro-solid-svg-icons/faUserShield'
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faUsersMedical as fasUsersMedical } from '@fortawesome/pro-solid-svg-icons/faUsersMedical'
import { faWrench as fasWrench } from '@fortawesome/pro-solid-svg-icons/faWrench'
library.add(
  fabFacebookF,
  fabFacebookSquare,
  fabGithub,
  fabGithubSquare,
  fabGoogle,
  fabLinkedin,
  fabLinkedinIn,
  fabSkype,
  fabTwitter,
  fabTwitterSquare,
  fabYoutubeSquare,
  falArchive,
  falArrowLeft,
  falCheck,
  falCheckCircle,
  falCog,
  falDigging,
  falEnvelopeOpen,
  falFile,
  falFilter,
  falHourglassHalf,
  falLockOpenAlt,
  falMapMarkerAlt,
  falPaperPlane,
  falPlus,
  falPlusCircle,
  falQuestionCircle,
  falRepeat,
  falRocketLaunch,
  falSlash,
  falStar,
  falSync,
  falTags,
  falTimes,
  falTrashAlt,
  falTrophy,
  falUserCircle,
  falUserTimes,
  farAddressCard,
  farArchive,
  farArrowDown,
  farArrowUp,
  farBan,
  farBars,
  farBookmark,
  farBriefcase,
  farBrowser,
  farBullhorn,
  farCalendarAlt,
  farCalendarCheck,
  farChartBar,
  farCheck,
  farCheckCircle,
  farCheckDouble,
  farCheckSquare,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farChevronSquareRight,
  farChevronUp,
  farCircle,
  farClipboard,
  farClock,
  farCog,
  farCogs,
  farCommentCheck,
  farCommentDots,
  farCommentPlus,
  farComments,
  farConstruction,
  farCrown,
  farDesktop,
  farDigging,
  farEdit,
  farEllipsisH,
  farEllipsisV,
  farEnvelope,
  farEnvelopeOpen,
  farEuroSign,
  farExclamationCircle,
  farExclamationTriangle,
  farExternalLink,
  farEye,
  farEyeSlash,
  farFile,
  farFileImage,
  farFilePlus,
  farFilter,
  farFlag,
  farFlagCheckered,
  farFolders,
  farGlobe,
  farHandPointUp,
  farHeart,
  farHistory,
  farHourglass,
  farHourglassHalf,
  farInbox,
  farInboxIn,
  farInboxOut,
  farInfo,
  farInfoCircle,
  farLink,
  farListUl,
  farLockAlt,
  farLockOpen,
  farMapMarkerAlt,
  farMinusCircle,
  farMobile,
  farMousePointer,
  farNewspaper,
  farPaperclip,
  farPause,
  farPen,
  farPenSquare,
  farPhone,
  farPhoneAlt,
  farPlusCircle,
  farQuestionCircle,
  farRedoAlt,
  farRepeat,
  farRobot,
  farRocketLaunch,
  farSearch,
  farSearchPlus,
  farShieldAlt,
  farShieldCheck,
  farShoppingCart,
  farSignInAlt,
  farSignOut,
  farSort,
  farSpinnerThird,
  farStar,
  farStopwatch,
  farSync,
  farTachometerAltAverage,
  farTag,
  farTags,
  farTasks,
  farThumbsUp,
  farTicketAlt,
  farTimes,
  farTimesCircle,
  farTimesOctagon,
  farTimesSquare,
  farTrash,
  farTrophy,
  farUpload,
  farUser,
  farUserCircle,
  farUserEdit,
  farUserShield,
  farUsers,
  farUsersCrown,
  fasAddressCard,
  fasArrowSquareUp,
  fasArrows,
  fasAward,
  fasBan,
  fasBars,
  fasBell,
  fasBolt,
  fasBookmark,
  fasBriefcase,
  fasCheck,
  fasCheckCircle,
  fasChevronLeft,
  fasCircle,
  fasCommentAltPlus,
  fasCommentCheck,
  fasCommentsAlt,
  fasCopy,
  fasCreditCard,
  fasEnvelope,
  fasExclamationCircle,
  fasExclamationSquare,
  fasExclamationTriangle,
  fasExternalLink,
  fasFile,
  fasFileCertificate,
  fasFileInvoice,
  fasFlagCheckered,
  fasFolderPlus,
  fasFolders,
  fasHeart,
  fasInbox,
  fasInfoCircle,
  fasLink,
  fasLockAlt,
  fasMapMarkerAlt,
  fasMinusOctagon,
  fasPhoneAlt,
  fasPlusCircle,
  fasQuestionCircle,
  fasRedo,
  fasRobot,
  fasRssSquare,
  fasShieldCheck,
  fasSortAlt,
  fasSquare,
  fasStar,
  fasStarHalf,
  fasStars,
  fasTachometerAltAverage,
  fasTags,
  fasThumbsDown,
  fasThumbsUp,
  fasTicketAlt,
  fasTimes,
  fasTimesCircle,
  fasTrash,
  fasTrashAlt,
  fasTrophy,
  fasTrophyAlt,
  fasUndoAlt,
  fasUnlockAlt,
  fasUserCircle,
  fasUserEdit,
  fasUserShield,
  fasUsers,
  fasUsersMedical,
  fasWrench
)
const css = dom.css()
dom.watch()
dom.insertCss(css)
